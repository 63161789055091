import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { DatePicker, Space, Button } from 'antd';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Store from '@material-ui/icons/Store';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import { API } from '../../config/api';
import formatDecimal from '../../utils/formatDecimal';
import BarChart from 'components/Charts/BarChart';
import BarChartCity from 'components/Charts/BarChartCity';

import LineChart from 'components/Charts/LineChart';
import SalesMap from 'components/Charts/MapChart';
import CircularProgressChart from 'components/Charts/CircularProgressChart';
import CircularProgress from '@material-ui/core/CircularProgress';

import PieChart from 'components/Charts/PieChart';
import moment from 'moment';
import 'moment/locale/pt-br'; // Importar o locale para português do Brasil
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle';
import Pedidos from 'views/Pedidos/Pedidos';
import { AttachMoney } from '@material-ui/icons';
import translate from 'components/Tradutor/tradutor';
import DataTable from 'components/Table/Table.js';
import PersonIcon from '@material-ui/icons/Person';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

const { RangePicker } = DatePicker; // Usar o RangePicker para seleção de intervalo

const useStyles = makeStyles(theme => ({
  ...styles,
  searchCardBody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2), // Espaço entre os elementos, ajuste conforme necessário
  },
  searchCard: {
    width: '100%',
    textAlign: 'center', // Opcional, se quiser garantir que o texto dentro do Card esteja centralizado
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(0, 0), // Adiciona margem superior e inferior
  },
}));

// Configuração da instância do Axios com o token
const axiosInstance = axios.create({
  baseURL: API.dashboard, // URL base da API
  headers: {
    'Content-Type': 'application/json',
    'x-access-token': sessionStorage.getItem('token'), // Token de acesso definido aqui
  },
});

function createData(cliente, status, pedidos, valor) {
  return {
    cliente,
    status,
    pedidos,
    valor,
  };
}

const rowHead = [
  {
    title: 'Vendedor',
    field: 'cliente',
    headerStyle: {
      width: 260,

      maxWidth: 360,
    },
    cellStyle: {
      width: 260,
      maxWidth: 360,

      textAlign: 'left',
      fontSize: '12px',
    },
  },

  {
    title: 'Status',
    field: 'status',
    cellStyle: {
      fontSize: '12px',
    },
  },

  {
    title: 'Pedidos',
    field: 'pedidos',
    cellStyle: {
      fontSize: '5px',
      whiteSpace: 'nowrap',
      textAlign: 'left',
      fontSize: '12px',
    },
  },

  {
    title: 'Valor',
    field: 'valor',

    headerStyle: {
      width: 100,
      whiteSpace: 'nowrap',
      textAlign: 'right',
      maxWidth: 100,
    },
    cellStyle: {
      width: 120,
      maxWidth: 120,
      whiteSpace: 'nowrap',
      textAlign: 'right',
      fontSize: '12px',
    },
  },
];
const rowHeadTransp = [
  {
    title: 'Transportadora',
    field: 'cliente',
    headerStyle: {
      width: 260,

      maxWidth: 360,
    },
    cellStyle: {
      width: 260,
      maxWidth: 360,

      textAlign: 'left',
      fontSize: '12px',
    },
  },

  {
    title: 'Status',
    field: 'status',
    cellStyle: {
      fontSize: '12px',
    },
  },

  {
    title: 'Pedidos',
    field: 'pedidos',
    cellStyle: {
      fontSize: '5px',
      whiteSpace: 'nowrap',
      textAlign: 'left',
      fontSize: '12px',
    },
  },

  {
    title: 'Valor',
    field: 'valor',

    headerStyle: {
      width: 100,
      whiteSpace: 'nowrap',
      textAlign: 'right',
      maxWidth: 100,
    },
    cellStyle: {
      width: 120,
      maxWidth: 120,
      whiteSpace: 'nowrap',
      textAlign: 'right',
      fontSize: '12px',
    },
  },
];
const rowHeadCliente = [
  {
    title: 'Cliente',
    field: 'cliente',
    headerStyle: {
      width: 360,

      maxWidth: 360,
    },
    cellStyle: {
      width: 360,
      maxWidth: 360,

      textAlign: 'left',
      fontSize: '12px',
    },
  },

  {
    title: 'Status',
    field: 'status',
    cellStyle: {
      fontSize: '12px',
    },
  },

  {
    title: 'Pedidos',
    field: 'pedidos',
    cellStyle: {
      fontSize: '5px',
      whiteSpace: 'nowrap',
      textAlign: 'left',
      fontSize: '12px',
    },
  },

  {
    title: 'Valor',
    field: 'valor',

    headerStyle: {
      width: 100,
      whiteSpace: 'nowrap',
      textAlign: 'right',
      maxWidth: 100,
    },
    cellStyle: {
      width: 120,
      maxWidth: 120,
      whiteSpace: 'nowrap',
      textAlign: 'right',
      fontSize: '12px',
    },
  },
];

const Dashboard = () => {
  const email = sessionStorage.getItem('email');
  const [vendasAno, setVendasAno] = useState([]);
  const [vendasMes, setVendasMes] = useState([]);
  const [vendasSemanal, setVendasSemanal] = useState([]);
  const [meta, setMeta] = useState('');
  const [metaAferida, setMetaAferida] = useState('');

  const [totFaturado, setTotFaturado] = useState('');

  const [vendas, setVendas] = useState('');
  const [vendasPessoal, setVendasPessoal] = useState('');

  const [qtdVendas, setQtdVendas] = useState('');
  const [qtdVendasPessoal, setQtdVendasPessoal] = useState('');

  const [qtdVendasAberto, setQtdVendasAberto] = useState('');
  const [vlVendasAberto, setVlVendasAberto] = useState('');
  const [qtdVendasAbertoPeriodo, setQtdVendasAbertoPeriodo] = useState('');
  const [vlVendasAbertoPeriodo, setVlVendasAbertoPeriodo] = useState('');

  const [qtdpedidosEmitidos, setQtdPedidosEmitidos] = useState('');
  const [vlpedidosEmitidos, setVlPedidosEmitidos] = useState('');

  const [qtdpedidosFinalizados, setQtdPedidosFinalizados] = useState(0);
  const [vlpedidosFinalizados, setVlPedidosFinalizados] = useState(0);

  const [vendasStatusValor, setVendasStatusValor] = useState('');
  const [vendasStatusQtd, setVendasStatusQtd] = useState('');
  const [vendedoresStatus, setVendedoresStatus] = useState('');
  const [clientesStatus, setClientesStatus] = useState('');
  const [transportadorasStatus, setTransportadorasStatus] = useState('');
  const [estadoCidadesMap, setEstadoCidadesMap] = useState(null);

  const [companyData, setCompanyData] = useState([]);
  const [annualRevenueData, setAnnualRevenueData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(moment().startOf('month')); // Inicializa com o primeiro dia do mês atual
  const [endDate, setEndDate] = useState(moment().endOf('month')); // Inicializa com o último dia do mês atual

  const classes = useStyles();

  const id_user = sessionStorage.getItem('id');

  const transformDataForTable = vendedorGroups => {
    const tableData = [];

    // Itera sobre cada vendedor
    Object.keys(vendedorGroups).forEach(vendedor => {
      const statusData = vendedorGroups[vendedor];

      // Itera sobre cada status dentro do vendedor
      Object.keys(statusData).forEach(status => {
        const { valor, pedidos } = statusData[status];

        // Adiciona cada status como uma linha na tabela
        tableData.push({
          cliente: vendedor,
          status: status,
          valor: valor,
          pedidos: pedidos,
        });
      });
    });

    return tableData;
  };

  useEffect(() => {
    // Configurar o locale para português do Brasil
    moment.locale('pt-br');
    const startDate = moment().startOf('month');
    const endDate = moment().endOf('month');
    fetchData(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
  }, []); // Adiciona startDate e endDate como dependências para refetch quando mudarem

  async function fetchData(startDate, endDate) {
    setLoading(true);

    try {
      var [
        pedidos,
        pedidosemaberto,
        vendas,
        //  vendasMesResponse,
      ] = await Promise.all([
        axiosInstance.get(
          `/qtdvendasmes?email=${email}&DATE_START=${startDate}&DATE_END=${endDate}`,
        ),
        axiosInstance.get(`/qtdvendasmes?email=${email}`),
        axiosInstance.get(
          `/vlvendasmes?email=${email}&DATE_START=${startDate}&DATE_END=${endDate}`,
        ),
        //    axiosInstance.get(`/totalfaturado?email=${email}&DATE_START=${startDate}&DATE_END=${endDate}`),
      ]);

      console.log(pedidos);
      console.log(pedidosemaberto);
      let totSum = 0;
      let totSumPersonal = 0;

      let totVendas = 0;
      let totVendasPessoal = 0;
      const sumByMonthYear = {};
      const sumByCompany = {};
      const sumByYear = {};

      console.log(vendas);

      vendas.data.data.forEach(item => {
        // Somatório total
        totSum += item.sum_totalValue;

        if (item.salesperson_id == id_user) {
          totVendasPessoal++;

          totSumPersonal += item.sum_totalValue;
        }

        totVendas++;
        // Somatório por mês/ano
        const monthYearKey = `${String(item.invoice_month).padStart(2, '0')}/${
          item.invoice_year
        }`;
        if (!sumByMonthYear[monthYearKey]) {
          sumByMonthYear[monthYearKey] = 0;
        }
        sumByMonthYear[monthYearKey] += item.sum_totalValue;

        // Agrupar por empresa
        if (!sumByCompany[item.company_code]) {
          sumByCompany[item.company_code] = 0;
        }
        sumByCompany[item.company_code] += item.sum_totalValue;

        // Agrupar por ano
        if (!sumByYear[item.invoice_year]) {
          sumByYear[item.invoice_year] = 0;
        }
        sumByYear[item.invoice_year] += item.sum_totalValue;
      });

      const sumByMonthYearArray = Object.entries(sumByMonthYear).map(
        ([key, value]) => ({
          label: key,
          value: value,
        }),
      );

      const companyDataArray = Object.entries(sumByCompany).map(
        ([key, value]) => ({
          label: key,
          value: value > 0 ? value : 0,
        }),
      );

      const annualRevenueArray = Object.entries(sumByYear).map(
        ([key, value]) => ({
          label: key,
          value: value,
        }),
      );

      setMeta(formatDecimal(pedidos.data[0]?.VALOR || 0));
      setVendasAno(sumByMonthYearArray);
      setVendas(totSum > 0 ? totSum : 0);
      setVendasPessoal(totSumPersonal);
      setQtdVendas(totVendas);
      setQtdVendasPessoal(totVendasPessoal);
      setEstadoCidadesMap(pedidos.data.data.estadoCidadesMap);
      setCompanyData(companyDataArray);
      setAnnualRevenueData(annualRevenueArray);

      const rows = transformDataForTable(pedidos.data.data.vendedorGroups);
      const rowsClientes = transformDataForTable(
        pedidos.data.data.clienteGroups,
      );

      const totFaturado = totSum;

      const percentage = meta > 0 ? ((totFaturado / meta) * 100).toFixed(2) : 0;

      setMetaAferida(percentage);

      const rowsListCli = rowsClientes
        ? rowsClientes.map(item => {
            const row = createData(
              item.cliente ? item.cliente : '',
              translate(item.status),
              item.pedidos,
              item.valor.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }),
            );
            return row;
          })
        : [{ error: 'Não encontrado' }];

      const rowsList = rows
        ? rows.map(item => {
            const row = createData(
              item.cliente ? item.cliente : '',
              translate(item.status),
              item.pedidos,
              item.valor.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }),
            );
            return row;
          })
        : [{ error: 'Não encontrado' }];

      const rowsTransp = transformDataForTable(
        pedidos.data.data.transportadoraGroups,
      );
      const rowsCli = transformDataForTable(pedidos.data.data.clienteGroups);

      const rowsListTransp = rowsTransp
        ? rowsTransp.map(item => {
            const row = createData(
              item.cliente != 'undefined' ? item.cliente : 'Não informado',
              translate(item.status),
              item.pedidos,
              item.valor.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }),
            );
            return row;
          })
        : [{ error: 'Não encontrado' }];

      console.log(rowsList);
      setVendedoresStatus(rowsList);
      setTransportadorasStatus(rowsListTransp);
      setClientesStatus(rowsCli);
      const arrayPedidosValor = [];
      const arrayPedidosQtd = [];
      const arrayPedidosValoremAberto = [];
      const arrayPedidosQtdemAberto = [];
      const arrayClientes = [];

      Object.keys(pedidos.data.data.statusVenda).forEach(status => {
        const objetoPedidos = {
          label: translate(status),
          value: pedidos.data.data.statusVenda[status].valor,
        };

        const objetoPedidosQtd = {
          label: translate(status),
          value: pedidos.data.data.statusVenda[status].pedidos,
        };

        // Adiciona o objeto ao array
        arrayPedidosValor.push(objetoPedidos);
        arrayPedidosQtd.push(objetoPedidosQtd);
      });

      Object.keys(pedidosemaberto.data.data.statusVenda).forEach(status => {
        const objetoPedidos = {
          label: translate(status),
          value: pedidosemaberto.data.data.statusVenda[status].valor,
        };

        const objetoPedidosQtd = {
          label: translate(status),
          value: pedidosemaberto.data.data.statusVenda[status].pedidos,
        };

        // Adiciona o objeto ao array
        arrayPedidosValoremAberto.push(objetoPedidos);
        arrayPedidosQtdemAberto.push(objetoPedidosQtd);
      });

      console.log(arrayPedidosValor);
      setVendasStatusQtd(arrayPedidosQtd);
      setVendasStatusValor(arrayPedidosValor);

      console.log(arrayPedidosQtd);

      // Labels que devem ser CONSIDERADOS para pedidos em ABERTO
      const labelsToSum = ['PREPARANDO', 'PREPARADO', 'APROVADO', 'SEPARACAO'];
      const labelsToSumFinished = ['FINALIZADO'];

      // Obtem Pedidos em Aberto Geral
      const ttPedidosemAberto = arrayPedidosQtdemAberto
        .filter(item => labelsToSum.includes(item.label))
        .reduce((sum, item) => sum + item.value, 0);
      if (ttPedidosemAberto) {
        setQtdVendasAberto(ttPedidosemAberto);
      } else {
        setQtdVendasAberto(0);
      }
      const vlPedidosemAberto = arrayPedidosValoremAberto
        .filter(item => labelsToSum.includes(item.label)) // Filtra os objetos com os labels especificados
        .reduce((sum, item) => sum + item.value, 0);
      if (vlPedidosemAberto) {
        setVlVendasAberto(
          vlPedidosemAberto.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }),
        );
      } else {
        setVlVendasAberto(
          '0'.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }),
        );
      }

      // Obtem Pedidos em aberto no periodo
      const ttPedidosemAbertoporPeriodo = arrayPedidosQtd
        .filter(item => labelsToSum.includes(item.label))
        .reduce((sum, item) => sum + item.value, 0);
      if (ttPedidosemAbertoporPeriodo) {
        setQtdVendasAbertoPeriodo(ttPedidosemAbertoporPeriodo);
      } else {
        setQtdVendasAbertoPeriodo(0);
      }
      const vlPedidosemAbertoporPeriodo = arrayPedidosValor
        .filter(item => labelsToSum.includes(item.label)) // Filtra os objetos com os labels especificados
        .reduce((sum, item) => sum + item.value, 0);
      if (vlPedidosemAbertoporPeriodo) {
        setVlVendasAbertoPeriodo(
          vlPedidosemAbertoporPeriodo.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }),
        );
      } else {
        setVlVendasAbertoPeriodo(
          '0'.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }),
        );
      }

      //obtem pedidos emitidos

      const ttPedidosEmitidos = arrayPedidosQtd.reduce(
        (sum, item) => sum + item.value,
        0,
      );
      if (ttPedidosEmitidos) {
        setQtdPedidosEmitidos(ttPedidosEmitidos);
      }
      const vlPedidosEmitidos = arrayPedidosValor.reduce(
        (sum, item) => sum + item.value,
        0,
      );
      if (vlPedidosEmitidos) {
        setVlPedidosEmitidos(
          vlPedidosEmitidos.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }),
        );
      }

      //obtem pedidos finalizados
      console.log(arrayPedidosQtd);
      const ttPedidosFinalizados = arrayPedidosQtd
        .filter(item => labelsToSumFinished.includes(item.label))
        .reduce((sum, item) => sum + item.value, 0);
      if (ttPedidosFinalizados) {
        setQtdPedidosFinalizados(ttPedidosFinalizados);
      } else {
        setQtdPedidosFinalizados(0);
      }
      const vlPedidosFinalizados = arrayPedidosValor
        .filter(item => labelsToSumFinished.includes(item.label))
        .reduce((sum, item) => sum + item.value, 0);
      if (vlPedidosFinalizados) {
        setVlPedidosFinalizados(
          vlPedidosFinalizados.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }),
        );
      } else {
        setVlPedidosFinalizados(
          '0'.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }),
        );
      }

      // setVendasMes(vendasMesResponse.data);
      //  setVendasSemanal(vendasSemanalResponse?.data);
      //  setTotFaturado(faturadoResponse?.data[0]?.TOTAL);
      // setVendas(formatDecimal(vendasDiaResponse?.data[0]?.TOTAL));
      setLoading(false);
    } catch (error) {
      setQtdVendasAberto(0);
      setVlVendasAberto(0);
      setVlVendasAbertoPeriodo(0);
      setQtdVendasAbertoPeriodo(0);
      setVlPedidosEmitidos(0);
      setQtdPedidosEmitidos(0);
      setVlPedidosFinalizados(0);
      setQtdPedidosFinalizados(0);
      setMeta(0);
      setVendasAno([]);
      setVendas(0);
      setVendasPessoal(0);
      setQtdVendas(0);
      setQtdVendasPessoal(0);
      setEstadoCidadesMap([]);
      setCompanyData([]);
      setAnnualRevenueData([]);

      //      sessionStorage.clear();
      setLoading(false);
    }
  }

  const handleSearch = () => {
    // Verifica se a data de fim não é anterior à data de início
    if (endDate.isBefore(startDate)) {
      toast.error('Data de fim não pode ser anterior à data de início!');
      return;
    }
    fetchData(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
  };

  return (
    <div className={classes.dashboardContainer}>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={classes.searchContainer}>
            <Card className={classes.searchCard}>
              <CardBody className={classes.searchCardBody}>
                <Space direction="horizontal" size={12}>
                  <DatePicker
                    placeholder="Data de Início"
                    value={startDate}
                    onClick={date => setStartDate(null)}
                    onChange={date => setStartDate(date)}
                    format="DD/MM/YYYY"
                  />
                  <DatePicker
                    placeholder="Data de Fim"
                    value={endDate}
                    onClick={date => setEndDate(null)}
                    onChange={date => setEndDate(date)}
                    format="DD/MM/YYYY"
                  />
                  <Button type="primary" onClick={handleSearch}>
                    Pesquisar
                  </Button>
                </Space>
              </CardBody>
            </Card>
          </div>
          <div className={classes.searchBar}>
            <GridContainer
              styles={{ justify: 'center', justifyContent: 'center' }}
            >
              <GridItem xs={12} sm={6} md={6} lg={4}>
                <Card>
                  <CardHeader color="info" stats icon>
                    <CardIcon color="info">
                      <Icon>assessment</Icon>
                    </CardIcon>
                    <div style={{ textAlign: 'center', marginTop: '8px' }}>
                      <p className={classes.cardCategory}>
                        Total de vendas em aberto
                      </p>
                      <b>
                        <h4 className={classes.cardTitle}>{vlVendasAberto}</h4>
                      </b>
                    </div>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <ArrowUpward className={classes.upArrowCardCategory} />
                      <span style={{ fontSize: '0.9rem', color: '#777' }}>
                        {qtdVendasAberto} pedidos
                      </span>
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={6} lg={4}>
                <Card>
                  <CardHeader color="info" stats icon>
                    <CardIcon color="info">
                      <Icon>trending_up</Icon>
                    </CardIcon>
                    <div style={{ textAlign: 'center', marginTop: '8px' }}>
                      <p className={classes.cardCategory}>
                        Total de vendas no período
                      </p>
                      <b><h4 className={classes.cardTitle}>
                         {vlpedidosEmitidos}
                      </h4></b>
                    </div>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <ArrowUpward className={classes.upArrowCardCategory} />
                      <span style={{ fontSize: '0.9rem', color: '#777' }}>
                      {qtdpedidosEmitidos} pedidos
                      </span>
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              {/*
              <GridItem xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="info" stats icon>
                    <CardIcon color="info">
                      <Icon>trending_up</Icon>
                    </CardIcon>
                    <div style={{ textAlign: 'center', marginTop: '8px' }}>
                      <p className={classes.cardCategory}>
                        Pedidos de venda finalizados
                      </p>
                      <h4 className={classes.cardTitle}>
                        {qtdpedidosFinalizados} / {vlpedidosFinalizados}
                      </h4>
                    </div>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <ArrowUpward className={classes.upArrowCardCategory} />
                      <span style={{ fontSize: '0.9rem', color: '#777' }}>
                        Quantidade e valor de pedidos finalizados no período
                        selecionado
                      </span>
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
*/}
              <GridItem xs={12} sm={6} md={6} lg={4}>
                <Card>
                  <CardHeader color="info" stats icon>
                    <CardIcon color="info">
                      <AttachMoney />
                    </CardIcon>
                    <p className={classes.cardCategory}>Total faturado no período</p>
                    <h4 className={classes.cardTitle}>
                      R${formatDecimal(vendas)}
                    </h4>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <ArrowUpward className={classes.upArrowCardCategory} />
                      <span style={{ fontSize: '0.9rem', color: '#777' }}>
                        Faturamento no período
                      </span>{' '}
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>

              {/* Gráfico de Pizza: Vendas por Empresa */}
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <Card>
                  <CardHeader color="info">
                    <h4 className={classes.cardTitleWhite}>
                      Vendas por Empresa
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <PieChart
                      data={companyData.map(data => data.value)}
                      labels={companyData.map(data => data.label)}
                      title="Faturamento por empresa"
                      subtitle="Valor faturado por empresa"
                      meta=""
                      numberFormat="currency"
                    />
                  </CardBody>
                </Card>
              </GridItem>

              {/* Gráfico de Faturamento Anual */}
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <CardHeader color="info">
                    <h4 className={classes.cardTitleWhite}>
                      Faturamento Anual no período selecionado
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <BarChart
                      data={annualRevenueData}
                      orientation="vertical"
                      title=""
                      subtitle=""
                      meta=""
                      numberFormat="currency" // Define o formato como moeda
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={6}>
                <BarChart
                  data={vendasAno}
                  orientation="vertical" // ou "vertical"
                  title="Faturamento por mês"
                  subtitle="Faturamento x mês"
                  meta=""
                  numberFormat="currency" // Define o formato como moeda
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={6}>
                <BarChartCity
                  data={estadoCidadesMap}
                  orientation="horizontal" // ou "vertical"
                  title="Pedidos por Estado"
                  subtitle="Quantidade de pedidos finalizados por estados"
                  meta=""
                  numberFormat="number" // Define o formato como moeda
                />
              </GridItem>
            </GridContainer>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
